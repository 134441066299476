// Libs
import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// images
import MapImage from 'images/mapsImages/aguasclaras-index.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';
import AddressTimes from 'components/units/unitPages/unit/addressTimes';
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';
import BreadCrumb from 'components/breadCrumb';
import Components from 'components/CmsComponents/';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  padding: ${props => props.padding};

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    padding: ${props => props.paddingLaptop};
    ${props =>
      props.borderTopLaptop &&
      css`
        border-top: 1px solid #c6c8cc;
      `}

    ${props =>
      props.borderBottomLaptop &&
      css`
        border-bottom: 1px solid #c6c8cc;
      `}

    ${props =>
      props.marginLaptop &&
      css`
        margin: ${props.marginLaptop};
      `}
  }
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = page => (
  <FeaturedMarketing
    WithoutItemBg
    singleText={getMarkdown(page, 'cliuo50gz6hmg0bn5kl7fxdph')}
    servicesList={getMarkdown(page, 'cliuo50gz6hmg0bn5kl7fxdph', true)}
    asset={getMarkdown(page, 'cliuo50gz6hmg0bn5kl7fxdph', false, true)}
    containerNoBorderBottomDesk
    containerMarginBottomLap="0"
  />
);

const renderPage = (page, isDesktop, location) => {
  const components = page[0]?.components ?? [];

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <BreadCrumb
          details
          markdown={getMarkdown(page, 'cliuogo1421xd0bmyrtouy8xf', true)}
        />
        <DetailsTitle
          titleWidth="34rem"
          titleWidthMobile="16rem"
          markdown={getMarkdown(page, 'cliunvok46dap0bn5meort172', true)}
        />
      </Part>
      {!isDesktop && renderFeaturedMarketing(page)}
      <Container>
        <Part gridColumn={'1 / span 6'}>
          {isDesktop && renderFeaturedMarketing(page)}
          {/* About */}
          <TextSection
            isAbout
            isAboutMobile
            gridArea="ABT"
            isShowing
            doNotApplyDisplayBlock
            markdown={getMarkdown(page, 'cliuoj97u6n920bn5dbzako0q', true)}
          />
          {/* Structure */}
          <TextSection
            markdown={getMarkdown(page, 'cliuosxs423yh0bljrnihnkrm', true)}
            gridArea="STRUC"
            isAboutMobile
            isShowing
          />
          {/* Others Units Mobile */}
          <TextSection
            markdown={getMarkdown(page, 'cliuoyuo726yv0bljkg06x884', true)}
            isShort={true}
            gridArea="PAR"
            isAboutMobile
            isOthersUnits
          />
        </Part>
        <Part gridColumn={'8 / span 5'}>
          <AddressTimes
            isShowingAttention
            markdown={getMarkdown(page, 'cliuodsxn20ka0bmylm38l7t2', true)}
            fragments={getMarkdown(page, 'cliuodsxn20ka0bmylm38l7t2')}
            location={{ lat: -15.83151940861746, lng: -48.04097354555284 }}
            zoom={16}
            isDetails
            isWhatsapp
            mapPreview={MapImage}
            isStatic
            isDesktop={isDesktop}
          />
          {/* Schedule */}
          <TextSection
            markdown={getMarkdown(page, 'cliuovpkm2e9n0bn5e0uyv45d', true)}
            gridArea="SCH"
            isShort
            isAboutMobile
            isShowing
          />
          {/* Parking */}
          <TextSection
            markdown={getMarkdown(page, 'cliuolo7020kg0bljcdevsxw6', true)}
            gridArea="PAR"
            isShort
            isAboutMobile
            isShowing
          />
          {/* Others Units */}
          <TextSection
            markdown={getMarkdown(page, 'cliuoyuo726yv0bljkg06x884', true)}
            isShort={true}
            gridArea="PAR"
            isAboutMobile
            isOthersUnits
            isShowing
            isOthersUnitsMobile
          />
          {isDesktop && (
            <SocialShare location={location} title="Unidade Águas Claras" />
          )}
        </Part>
      </Container>
      {!isDesktop && (
        <SocialShare location={location} title="Unidade Águas Claras" />
      )}
      <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
        {components.length >= 1 &&
          components?.map(component => {
            return Components(component);
          })}
      </Part>
    </GridContainer>
  );
};

const OncologiaAsaSul = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'cliuo50gz6hmg0bn5kl7fxdph', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cliunvtx21zai0bn5xcjyehne" }) {
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_ListQAContent {
                    id
                    name
                    title
                    subTitle
                    type
                    isDesignSystem

                    anchorTo
                    faqLink
                    backgroundColor {
                      hex
                    }
                    qaData {
                      ... on GraphCMS_CustomFragment {
                        id
                        name
                        markdown
                        singletexts
                      }
                      ... on GraphCMS_Qa {
                        id
                        autor
                        pergunta
                        titulo
                        doctors(first: 1000) {
                          id
                          id_api
                          active
                          assignment
                          name
                        }
                      }
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <OncologiaAsaSul
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
